<template>
  <div class="w-full relative" id="container-search">
    <div
      class="absolute bottom-12 left-1/2 transform -translate-x-1/2 z-20 w-[45rem] lg:bottom-0 lg:w-full"
    >
      <EmptyStateOnMap
        title="Parcelle"
        name_icon="batiments"
        v-if="!is_data_loading && error_show_address"
      >
        <template #message-error>
          <span class="text-promy-gray-250">{{ error_show_address }}</span>
        </template>
      </EmptyStateOnMap>
    </div>

    <div
      v-if="is_data_loading"
      class="bg-loader flex justify-center items-center absolute w-full h-full top-0 left-0 z-10"
    >
      <LoaderPromy
        classes="w-6"
        :size_bg_promy="0"
        :color="'#ffffff'"
        class="items-center justify-center flex"
      >
      </LoaderPromy>
    </div>
    <div class="absolute left-12 bottom-16 z-30 lg:z-10 lg:left-4 space-y-2">
      <MapType />
    </div>
    <RevelationMobileButton
      classes="hidden sm:block absolute z-10 bottom-0 px-6 py-3 border-promy-blue-200 rounded-none w-full font-semibold"
    />
    <div class="w-full height-container-mapbox">
      <div id="mapbox"></div>
    </div>

    <div class="absolute top-4 right-10 sm:right-4 z-10">
      <Tips />
    </div>
    <div
      class="absolute left-1/2 transform -translate-x-1/2 max-w-7xl top-20 flex flex-col w-full self-center px-8"
    >
      <div
        class="flex sm:relative sm:flex-col sm:space-y-3 justify-center bg-promy-green-350 rounded-[10px] p-6"
      >
        <div class="w-4/5 shadow-lg md:w-11/12 pr-4 sm:pr-0 sm:w-full">
          <div class="relative">
            <ProIcon
              name="search"
              color="#10AAAF"
              class="z-10 absolute left-6 top-[22px] sm:top-4 sm:left-4"
            />
          </div>
          <pro-auto-complete
            is_auto_complete_cross_api
            placeholder="Recherche"
            class="w-full"
            inputClasses="!pl-14 !h-[64px] sm:!h-12  sm:!pl-[50px]"
            :list="autocomplete_items"
            v-model="autocomplete_item"
            :args="['properties', 'label']"
            @setItem="setItemForAutocomplete"
            :is_loading="is_autocomplete_fetching"
            :is_searchable="true"
          />
        </div>
        <div
          class="flex items-center sm:absolute sm:right-0 sm:bottom-[-55px] sm:p-3 bg-white px-6 md:px-[10px] rounded-lg shadow-lg cursor-pointer lg:w-1/4 md:w-auto md:rounded-full"
          @click="getActualLocalisation"
        >
          <span
            class="text-promy-green-350 font-semibold text-base leading-6 mr-3 md:hidden"
          >
            Ma localisation
          </span>
          <ProIcon name="location" color="#10AAAF" height="21px" width="21px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      search_value: '',
      is_search_wanted: false,
      is_search_selected: false,
      autocomplete_items: [],
      autocomplete_item: '',
      is_autocomplete_fetching: false,
      AddressIsSelecting: false,
    }
  },
  mixins: [Mapbox],
  mounted() {
    this.loadMap()
  },
  created() {
    this.checkIncomingAdress()
    this.verifyUser()
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
      error_show_address: 'address/error_show_address',
      is_data_loading: 'address/isAddressStoredAndRetrieved',
      user: 'auth/user',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  methods: {
    ...mapActions({
      storeAddress: 'address/storeAddress',
      showAddressWithoutAuth: 'address/showAddressWithoutAuth',
      getReverseAddress: 'address/getReverseAddress',
    }),
    resetStateParcelles() {
      this.$store.commit('parcelle/CURRENT_PARCELLE', null)
      this.$store.commit('parcelle/PARCELLES_VOISINES', [])
    },
    checkIncomingAdress() {
      const { lat, lon, adress } = this.$route.query
      if (lat && lon) {
        this.$store.commit('address/SET_ADDRESS_STORED_AND_RETRIEVED', true)
        const url = this.isLoggedIn
          ? 'grand-publics/gouv-url/reverse'
          : 'grand-publics/gouv-url/reverse/no-auth'
        this.$http
          .get(url, {
            params: {
              lat: lat,
              lon: lon,
            },
          })
          .then(
            (response) => {
              if (response.data.features.length) {
                if (this.isLoggedIn) {
                  this.storeAddress(response.data.features[0]).then(
                    () => {},
                    (error) => {
                      if (error.response.status === 422) {
                        this.toast(
                          'Attention',
                          error.response.data.message,
                          'warning',
                        )
                      }
                    },
                  )
                } else {
                  this.$store.commit(
                    'auth/SET_GUEST_INTENDED_ADDRESS',
                    response.data.features[0],
                    { root: true },
                  )
                  this.resetStateParcelles()
                  this.showAddressWithoutAuth(response.data.features[0])
                }
              }
            },
            (error) => {
              if (error.response.status === 422) {
                this.$store.commit(
                  'address/SET_ADDRESS_STORED_AND_RETRIEVED',
                  false,
                )
                this.toast('Attention', error.response.data.message, 'warning')
              }
            },
          )
      } else if (adress) {
        this.$store.commit('address/SET_ADDRESS_STORED_AND_RETRIEVED', true)
        const url = this.isLoggedIn
          ? 'grand-publics/gouv-url'
          : 'grand-publics/gouv-url/no-auth'
        this.$http
          .get(url, {
            params: {
              limit: 1,
              q: adress,
            },
          })
          .then(
            (response) => {
              if (response.data.hasOwnProperty('features')) {
                if (this.isLoggedIn) {
                  this.storeAddress(response.data.features[0]).then(
                    () => {},
                    (error) => {
                      if (error.response.status === 422) {
                        this.toast(
                          'Attention',
                          error.response.data.message,
                          'warning',
                        )
                      }
                    },
                  )
                } else {
                  this.resetStateParcelles()
                  this.showAddressWithoutAuth(response.data.features[0])
                }
              }
            },
            (error) => {
              this.toast(
                'Attention',
                'Une erreur est survenue lors de la récupération des adresses',
                'warning',
              )
            },
          )
      }
    },
    verifyUser() {
      if (this.isLoggedIn) {
        let outsided_adress = this.user.outsided_adress
        this.$store.dispatch('auth/me').then(() => {
          if (!outsided_adress) return
          this.$store
            .dispatch('address/showAddress', outsided_adress)
            .then(() => {
              this.$router.push(`/sections-gu/parcelle/${outsided_adress}`)
            })
        })
      }
    },
    toggle_search_bar() {
      this.is_search_selected = true
      this.is_search_wanted = !this.is_search_wanted
    },
    setItemForAutocomplete(item_selected = null) {
      this.AddressIsSelecting = true
      this.autocomplete_item = item_selected
        ? item_selected.properties.label
        : null
      if (this.isLoggedIn) {
        this.storeAddress(item_selected).then(
          () => {},
          (error) => {
            if (error.response.status === 422) {
              this.toast('Attention', error.response.data.message, 'warning')
            }
          },
        )
      } else {
        this.$store.commit('auth/SET_GUEST_INTENDED_ADDRESS', item_selected)
        this.resetStateParcelles()
        this.showAddressWithoutAuth(item_selected).then(
          () => {},
          (error) => {
            if (error.response.status) {
              this.toast(
                'Attention',
                'Une erreur est survenue lors de la récupération des données',
                'warning',
              )
              this.$store.commit(
                'address/SET_ADDRESS_STORED_AND_RETRIEVED',
                false,
              )
            }
          },
        )
      }
    },
    getActualLocalisation() {
      // Getting informations from external service ...
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.getReverseAddress(pos.coords)
        },
        (error) => {
          console.error(error)
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      )
    },
    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      1000, // 1000 milliseconds
    ),
    async fetchAdresses() {
      this.is_autocomplete_fetching = true
      try {
        const url = this.isLoggedIn
          ? 'grand-publics/gouv-url'
          : 'grand-publics/gouv-url/no-auth'
        let response = await this.$http.get(url, {
          params: {
            limit: 30,
            q: this.autocomplete_item,
          },
        })
        this.autocomplete_items = []
        if (response.data.hasOwnProperty('features')) {
          this.autocomplete_items = response.data.features
        }
      } catch (error) {
        console.log(error)
        if (error.response.status == 422) {
          this.toast('', '', 'clear')
          this.toast('Attention', error.response.data.message, 'warning')
        }
      }
      this.is_autocomplete_fetching = false
    },
  },
  watch: {
    autocomplete_item: {
      handler() {
        if (!this.AddressIsSelecting) {
          this.searchAfterDebounce()
        }
        this.AddressIsSelecting = false
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-loader {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
